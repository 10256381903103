import React from "react";
import Breadcrumb from "./BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Col, Form, Modal, Popconfirm, Row, Tooltip } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { message } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import {
  addSubProcess,
  fetchProcess,
  updateSubProcess,
  deleteSubProcess,
} from "../redux-slice/processSlice";
export default function SubProcess(props) {
  console.log("----line 18--props---", props);
  const responseFromStore = useSelector((store) => store);
  const naviagte = useNavigate();
  const [subProcessToUpdate, setSubProcessToUpdate] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditSubProcess, setIsEditSubProcess] = useState(false);
  const [selectedSubProcessName, setSelectedSubProcessName] = useState("");
  const [selectedSubProcessId, setSelectedSubProcessId] = useState("");
  const [emission_factor, setSelectedSubProcessEmissionFactor] = useState("");
  const [unit, setSelectedSubProcessUnit] = useState("");
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [subProcess, setSubProcess] = useState([]);
  const [process, setProcess] = useState({});
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const location = useLocation();
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const showModal = () => {
    // setIsEditSubProcess(false);
    setIsModalOpen(true);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    confirm({
      closeDropdown: false,
    });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);

    // setSearchText("");
  };
  const handleAddProcessNavigate = () => {
    naviagte(`/processes/subprocess/addsubprocess`, { state: location.state });
  };
  const handleCancel = () => {
    setIsEditSubProcess(false);
    setIsModalOpen(false);
  };

  const onSubmit = async (subProcess) => {
    console.log("sub Process on submit", subProcess);
    let subProcessObjToSave, responseOfApi;
    try {
      if (isEditSubProcess) {
        console.log("subProcess in is edit sub process", subProcess);
        subProcessObjToSave = {
          id: subProcessToUpdate.id,
          process_id: props.location.state.id,
          name: subProcess.subProcessName,
          emission_factor: subProcess.emission_factor,
          unit: subProcess.unit,
        };
        responseOfApi = await dispatch(updateSubProcess(subProcessObjToSave));
      } else {
        subProcessObjToSave = [
          {
            process_id: props.location.state.id,
            name: subProcess.subProcessName,
            emission_factor: subProcess.emission_factor,
            unit: subProcess.unit,
          },
        ];
        responseOfApi = await dispatch(addSubProcess(subProcessObjToSave));
      }
      setIsEditSubProcess(false);
      console.log("responseOfApi", responseOfApi);
      if (responseOfApi.payload.status === 200) {
        setIsModalOpen(false);
        message.success(responseOfApi.payload.message);
        dispatch(fetchProcess());
      } else {
        setIsModalOpen(false);
        message.error(responseOfApi.payload.message);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, [], confirm, dataIndex);
              // handleReset([], confirm, dataIndex);
              // confirm({
              //   closeDropdown: false,
              // });
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const editSubProcess = (record) => {
    setIsEditSubProcess(true);
    setSelectedSubProcessId(record.id);
    setSelectedSubProcessName(record.name);
    setSelectedSubProcessUnit(record.unit);
    setSelectedSubProcessEmissionFactor(record.emission_factor);
    setSubProcessToUpdate(record);
    console.log("record:", record);
    setIsModalOpen(true);
  };
  const deleteTheSubProcess = async (subProcess) => {
    let deleteObj = {
      process_id: process.id,
      sub_process_id: subProcess.id,
    };
    let responseFromApi = await dispatch(deleteSubProcess(deleteObj));
    console.log("responseFromApi from delete", responseFromApi.payload);
    if (responseFromApi.payload.status === 204) {
      message.success("Sub Process deleted successfully");
      dispatch(fetchProcess());
    } else {
      message.error(responseFromApi.payload.message);
    }
  };
  useEffect(() => {
    dispatch(fetchProcess());
  }, [dispatch]);
  useEffect(() => {
    console.log("Subprocess:", responseFromStore);
    setSubProcess(
      responseFromStore.process.processes.filter((pro) => {
        return pro.id === process.id;
      })[0]?.subProcesses
    );
  }, [responseFromStore]);
  useEffect(() => {
    setProcess(location.state);
    console.log("Subprocess:", process);
    console.log(
      "Subprocess:",
      responseFromStore.process.processes.filter((pro) => {
        return pro.id === process.id;
      })
    );
  }, []);

  const columns = [
    {
      title: "Sub Process Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Emission Factor",
      dataIndex: "emission_factor",
      key: "emission_factor",
      width: "20%",
      align: "center",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit Process">
            <Button type="link" onClick={() => editSubProcess(record)}>
              <EditOutlined style={{ fontSize: "20px", color: "Blue" }} />
            </Button>
          </Tooltip>
          <Tooltip title="Delete Sub Process">
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteTheSubProcess(record)}
            >
              <DeleteOutlined style={{ fontSize: "20px", color: "red" }} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="container">
      <Row className="title-row">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Breadcrumb location={props.location} />
        </Col>
      </Row>
      <Row className="statistics-card">
        <Col span={24}>
          <div className="card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <div>{process.name}</div>
              <div style={{ flex: "0 0 " }}>
                <Button icon={<PlusOutlined />} onClick={showModal}>
                  Add Sub-Process
                </Button>
              </div>
            </div>
            <Table
              loading={responseFromStore.process.isloading}
              columns={columns}
              dataSource={subProcess}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title="Sub Process"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
        // style={{ top: 20 }}
        styles={{ body: { height: "180px", top: 20 } }}
      >
        <Form
          name="add-Group"
          className="add-Group"
          onFinish={onSubmit}
          initialValues={
            isEditSubProcess
              ? {
                  subProcessName: selectedSubProcessName,
                  emission_factor: emission_factor,
                  unit: unit,
                  key: selectedSubProcessId,
                }
              : ""
          }
        >
          <Form.Item name="key" style={{ display: "none" }}></Form.Item>
          <Form.Item
            name="subProcessName"
            rules={[
              {
                required: true,
                message: "Please Enter Sub Process Name!",
              },
            ]}
          >
            <Input
              autoFocus
              placeholder="Sub Process Name"
              name="subProcessName"
              //   value={selectedSubProcessName}
              //   onChange={this.onChange}
            />
          </Form.Item>
          <Form.Item
            name="emission_factor"
            rules={[
              {
                required: true,
                message: "Please Enter Emission Factor!",
              },
            ]}
          >
            <Input
              autoFocus
              placeholder="Emission Factor"
              name="emission_factor"
              //   value={selectedSubProcessEmissionFactor}
              //   onChange={this.onChange}
            />
          </Form.Item>
          <Form.Item
            name="unit"
            rules={[
              {
                required: true,
                message: "Please Enter Unit!",
              },
            ]}
          >
            <Input
              autoFocus
              placeholder="Unit"
              name="unit"
              // value={selectedSubProcessUnit}
              //   onChange={this.onChange}
            />
          </Form.Item>
          <Form.Item className="float-end">
            <Button className="close-modal me-3" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="ok-modal"
              //   loading={loading}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
