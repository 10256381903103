import React from "react";
import Breadcrumb from "./BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { EyeOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { fetchSupplyChains } from "./../redux-slice/supplyChainSlice";
export default function SupplyChain(props) {
  const response = useSelector((store) => store);
  const naviagte = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [supplyChains, setSupplyChain] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleNavigate = (row) => {
    console.log("Data:", row);
    naviagte(`/supply-chain/add-supply-chain`, { state: row });
  };
  const handleAddSupplyChainNavigate = () => {
    naviagte(`/supply-chain/add-supply-chain`);
  };
  const handleUpdateSupplyChainNavigate = (row) => {
    naviagte(`/supply-chain/update-supply-chain`, { state: row });
  };

  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    confirm({
      closeDropdown: false,
    });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);

    // setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, [], confirm, dataIndex);
              // handleReset([], confirm, dataIndex);
              // confirm({
              //   closeDropdown: false,
              // });
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSupplyChains());
  }, [dispatch]);
  useEffect(() => {
    console.log(
      "response ----------line 156:",
      response.supplyChain.supplyChains
    );
    setSupplyChain(response.supplyChain.supplyChains);
  }, [response]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Total Carbon Emission",
      dataIndex: "totalCarbonEmission",
      key: "totalCarbonEmission",
      render: (data, row) => {
        // const categoryName = data?.name;
        return data.toFixed(3);
      },
      width: "20%",
      // ...getColumnSearchProps("totalCarbonEmission"),
    },
    {
      title: "Category",
      dataIndex: "supplyChainCategoryMasterData",
      key: "category",
      render: (data, row) => {
        const categoryName = data?.name;
        return categoryName;
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (data, row) => (
        <Button
          type="link"
          onClick={() => handleUpdateSupplyChainNavigate(row)}
        >
          <EyeOutlined />
        </Button>
      ),
    },
  ];
  console.log("response", response);
  return (
    <div className="container">
      <Row className="title-row">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Breadcrumb location={props.location} />
        </Col>
      </Row>
      <Row className="statistics-card">
        <Col span={24}>
          <div className="card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <div>Supply Chains</div>
              <div style={{ flex: "0 0 " }}>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => handleAddSupplyChainNavigate()}
                >
                  Create Supply Chain
                </Button>
              </div>
            </div>
            <Table
              loading={response.supplyChain.isloading}
              columns={columns}
              dataSource={supplyChains}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
