import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchSupplyChainCategories = createAsyncThunk(
  "fetch supply chain categories",
  async () => {
    const data = await fetch(
      "http://localhost:8080/api/v1/supply-chain-category"
    );
    return data.json();
  }
);
const supplyChainCategorySlice = createSlice({
  name: "category",
  initialState: {
    isloading: true,
    isError: false,
    categories: [],
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupplyChainCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(fetchSupplyChainCategories.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.categories = [];
    });
    builder.addCase(fetchSupplyChainCategories.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.categories = [];
    });
  },
});
export default supplyChainCategorySlice.reducer;
