import React from "react";
import { Col, Row } from "antd";
import Breadcrumb from "./BreadCrumbs";

export default function Dashboard(props) {
  return (
    <>
      <div className="container">
        <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <Breadcrumb location={props.location} />
          </Col>
        </Row>
        <Row className="statistics-card">
          <Col span={24}>
            <div className="card">
              <div className="card-body">Welcome</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
