import React from "react";
import Breadcrumb from "./BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { message, notification } from "antd";
import {
  Col,
  Divider,
  Form,
  List,
  Modal,
  Popconfirm,
  Row,
  Select,
  Typography,
} from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  EyeOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { fetchProcess } from "../redux-slice/processSlice";
import { fetchSupplyChainCategories } from "./../redux-slice/supplyChainCategorySlice";
import { addSupplyChain } from "../redux-slice/supplyChainSlice";

export default function AddSupplyChain(props) {
  const response = useSelector((store) => store);
  const location = useLocation();
  const { Option } = Select;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [process, setProcess] = useState([]);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [subProcess, setSubProcess] = useState([]);
  const [subProcessUnit, setSubProcessUnit] = useState("");

  const [name, setName] = useState(" ");

  const onSubmit = async (mappings) => {
    console.log("Received values of form:", mappings);
    let newSupplyChain = {
      user_id: JSON.parse(localStorage.getItem("user")).id,
      category_id: category,
      name: name,
      supply_chain_mapping: mappings.supply_chain,
    };
    console.log("supplyChainObj", newSupplyChain);
    let result = await dispatch(addSupplyChain(newSupplyChain));
    console.log("result payload", result);
    console.log("result payload", result.payload);
    // navigate(`/supply-chain/update-supply-chain`, {
    //   state: response.supplyChain.supplyChainCreationResponse.data,
    // });
    // message.success("supply chain created successfully");
    if (result.payload.status === 200) {
      message.success("supply chain created successfully");
      // console.log(
      //   "supplyChainCreationResponse.data:",
      //   response.supplyChain.supplyChainCreationResponse.data
      // );
      navigate(`/supply-chain/update-supply-chain`, {
        state: result.payload.data,
      });
    }
  };
  const onChangeProcess = (value) => {
    console.log(`selected `, value);
    setSubProcess(
      process?.filter((process) => {
        return process.id === value;
      })[0]?.subProcesses
    );
  };
  const onNameChange = (event) => {
    console.log(`selected name `, event.target.value);
    setName(event.target.value);
  };
  const onChangeCategory = (value) => {
    console.log(`category selected`, value);
    setCategory(value);
  };
  const filterOptionProcess = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onSearchProcess = (value) => {
    console.log("search:", value);
  };
  const onChangeSubProcess = (value) => {
    console.log("onChangeSubProcess:");
    setSubProcessUnit(
      subProcess.filter((process) => {
        return process.id === value;
      })[0]?.unit
    );
    // console.log(
    //   `selected ${process.filter((process) => process.id === value)}`
    // );
  };
  const filterOptionSubProcess = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onSearchSubProcess = (value) => {
    console.log("search:", value);
  };

  useEffect(() => {
    dispatch(fetchProcess());
    dispatch(fetchSupplyChainCategories());
  }, [dispatch]);

  useEffect(() => {
    console.log("Process: of useeffect", response.process.processes);
    setCategories(response.supplyChainCategory.categories);
    setProcess(response.process.processes);
  }, [response]);

  return (
    <div className="container">
      <Row className="title-row">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Breadcrumb location={props.location} />
        </Col>
      </Row>
      <Row className="statistics-card">
        <Col span={24}>
          <div className="card">
            <Form
              name="dynamic_form_nest_item"
              onFinish={onSubmit}
              // autoComplete="off"
              initialValues={{
                supply_chain: [{}],
              }}
            >
              <Form.List name="supply_chain">
                {(fields, { add, remove, move }) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 10,
                      }}
                    >
                      <div>Supply Chain</div>

                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          add();
                          move(fields.length, 0);
                        }}
                      >
                        Add Sub-Process
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        // marginBottom: 8,
                        // display: "flex",
                        // justifyContent: "space-between",
                        padding: "4px 0 0 9px",
                      }}
                    >
                      {/* <Form.Item
                        name={[name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Name",
                          },
                        ]}
                      > */}
                      <Input
                        status={!name.length ? "error" : ""}
                        required
                        style={{ width: "308px", margin: "0 10px 15px 0px" }}
                        placeholder="Supply Chain Name"
                        onChange={onNameChange}
                      />
                      {/* </Form.Item> */}
                      {/* <Form.Item
                        // name={[name, "category"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing Process",
                          },
                        ]}
                      > */}
                      <Select
                        required
                        style={{ width: "200px" }}
                        showSearch
                        allowClear={true}
                        placeholder="Select a category"
                        optionFilterProp="children"
                        onChange={onChangeCategory}
                        filterOption={filterOptionProcess}
                        onSearch={onSearchProcess}
                      >
                        {categories?.map((category) => (
                          <Option value={category.id}>{category.name}</Option>
                        ))}
                      </Select>
                      {/* </Form.Item> */}
                    </div>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          // marginBottom: 8,
                          // display: "flex",
                          // justifyContent: "space-between",
                          padding: "4px 0 0 9px",
                        }}
                        align="baseline"
                      >
                        <Form.Item>Select Process : </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "process_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Process",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "200px" }}
                            showSearch
                            allowClear
                            placeholder="Select a process"
                            optionFilterProp="children"
                            onChange={onChangeProcess}
                            filterOption={filterOptionProcess}
                            onSearch={onSearchProcess}
                          >
                            {process?.map((group) => (
                              <Option value={group.id}>{group.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "sub_process_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Sub Process",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "200px" }}
                            showSearch
                            allowClear={true}
                            placeholder="Select a Sub Process"
                            optionFilterProp="children"
                            onChange={onChangeSubProcess}
                            filterOption={filterOptionSubProcess}
                            onSearch={onSearchSubProcess}
                          >
                            {subProcess?.map((group) => (
                              <Option value={group.id}>{group.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "quantity"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Quantity",
                            },
                          ]}
                        >
                          <Input
                            style={{ width: "180px" }}
                            placeholder="Quantity"
                            // suffix={subProcessUnit ? `${subProcessUnit}` : ""}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "sequence"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Sequence",
                            },
                          ]}
                        >
                          <Input placeholder="Sequence" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="primary"
                        className="ok-modal"
                        htmlType="submit"
                      >
                        Calculate
                      </Button>
                    </div>
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
}
