import React, { useState } from "react";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import { LayoutFilled, AppstoreFilled, UserOutlined } from "@ant-design/icons";

import { routes } from "../../util/constants";
// import logo from "../../assests/img/cloud.png";

import logo from "../../assests/img/global.png";
import Process from "./../Process";

export default function SiderMenu(props) {
  return (
    <div
      className={props.collapsed ? "sider-menu" : "sider-menu menu-position"}
    >
      <div className="side-header">
        <div className="brand-name">
          <div className="brand-logo">
            <div>
              <img className="logo" src={logo} alt="Logo" />
            </div>
            <span className="app-name" style={{ fontSize: "16px" }} mode="full">
              CFCE
            </span>
            <span className="app-name" mode="full"></span>
          </div>
        </div>
      </div>

      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[window.location.pathname]}
      >
        <Menu.Item key={routes.ADMIN_DASHBOARD} icon={<LayoutFilled />}>
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.ADMIN_DASHBOARD}
          >
            Dashboard
          </NavLink>
        </Menu.Item>
        <Menu.Item key={routes.PROCESS} icon={<LayoutFilled />}>
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.PROCESS}
          >
            Process
          </NavLink>
        </Menu.Item>
        <Menu.Item key={routes.SUPPLYCHAIN} icon={<LayoutFilled />}>
          <NavLink
            className="sider-links text-decoration-none"
            to={routes.SUPPLYCHAIN}
          >
            Supply Chain
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
}
