import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { addSubProcess } from './processSlice';

export const fetchProcess = createAsyncThunk("fetch processes", async () => {
  console.log("fetchprocess  called ");
  const data = await fetch("http://localhost:8080/api/v1/process");
  return data.json();
});
export const addProcess = createAsyncThunk("add process", async (body) => {
  console.log("Body:", body);
  const data = await axios.post("http://localhost:8080/api/v1/process", body);
  console.log("response create process:", data.data);
  return data.data;
});
export const updateProcess = createAsyncThunk(
  "update process",
  async (body) => {
    console.log("Body: inside update process", body);
    const data = await axios.put("http://localhost:8080/api/v1/process", body);
    console.log("response update process:", data.data);
    return data.data;
  }
);
export const deleteProcess = createAsyncThunk("delete process", async (id) => {
  console.log("id: inside delete process", id);
  const data = await axios.delete(`http://localhost:8080/api/v1/process/${id}`);
  console.log("response delete process:", data.data);
  return data.data;
});
export const deleteSubProcess = createAsyncThunk(
  "delete sub process",
  async (deleteObj) => {
    console.log(
      "id: inside delete process",
      deleteObj.process_id,
      deleteObj.sub_process_id
    );
    const data = await axios.delete(
      `http://localhost:8080/api/v1/process/${deleteObj.process_id}/subProcess/${deleteObj.sub_process_id}`
    );
    console.log("response delete process:", data.data);
    return data.data;
  }
);
export const addSubProcess = createAsyncThunk(
  "add sub process",
  async (body) => {
    // const dispatch = useDispatch();
    try {
      console.log("Body: in addSubProcess", body[0].process_id);
      const data = await axios.post(
        `http://localhost:8080/api/v1/process/${body[0].process_id}/subProcess`,
        body
      );
      console.log("response create sub Process process:", data.data);
      return data.data;
    } catch (error) {
      console.log("error", error);
    }
  }
);
export const updateSubProcess = createAsyncThunk(
  "update subprocess",
  async (body) => {
    console.log("Body: inside update sub process", body);
    const data = await axios.put(
      `http://localhost:8080/api/v1/process/${body.process_id}/subProcess`,
      body
    );
    console.log("response update sub process:", data.data);
    return data.data;
  }
);
// export const deleteSubProcess = createAsyncThunk(
//   "delete subprocess",
//   async (id) => {
//     console.log("id: inside delete sub process", id);
//     const data = await axios.delete(
//       `http://localhost:8080/api/v1/process/${process_id}/subProcess/${sub_process_id}`
//     );
//     console.log("response delete sub process:", data.data);
//     return data.data;
//   }
// );
const processSlice = createSlice({
  name: "process",
  initialState: {
    isloading: true,
    isError: false,
    processes: [],
    SubProcessCreationResponse: {},
    updateSubProcessResponse: {},
    updateProcessResponse: {},
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProcess.fulfilled, (state, action) => {
      state.processes = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(fetchProcess.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.processes = [];
    });
    builder.addCase(fetchProcess.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.processes = [];
    });
    builder.addCase(addProcess.fulfilled, (state, action) => {
      console.log("add process fullfilled:", action);
      // console.log("")
      state.processes = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(addProcess.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.processes = [];
    });
    builder.addCase(addProcess.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.processes = [];
    });

    builder.addCase(updateProcess.fulfilled, (state, action) => {
      console.log("add process fullfilled:", action);
      // console.log("")
      state.processes = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(updateProcess.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.processes = [];
    });
    builder.addCase(updateProcess.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.processes = [];
    });
    builder.addCase(addSubProcess.fulfilled, (state, action) => {
      console.log("add process fullfilled:", action);
      state.SubProcessCreationResponse = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(addSubProcess.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.SubProcessCreationResponse = [];
    });
    builder.addCase(addSubProcess.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.SubProcessCreationResponse = [];
    });
    builder.addCase(updateSubProcess.fulfilled, (state, action) => {
      console.log("add process fullfilled:", action);
      // console.log("")
      state.updateSubProcessResponse = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(updateSubProcess.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.updateSubProcessResponse = [];
    });
    builder.addCase(updateSubProcess.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.updateSubProcessResponse = [];
    });
  },
});
export default processSlice.reducer;
