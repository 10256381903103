import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
export const fetchSupplyChains = createAsyncThunk(
  "fetch supply chains",
  async () => {
    let userId = JSON.parse(localStorage.getItem("user")).id;
    const data = await fetch(
      `http://localhost:8080/api/v1/supply-chain?userId=${userId}`
    );
    return data.json();
  }
);
export const addSupplyChain = createAsyncThunk(
  "add supply chain",
  async (body) => {
    // const dispatch = useDispatch();
    console.log("Body: supply chain", body);
    const data = await axios.post(
      "http://localhost:8080/api/v1/supply-chain",
      body
    );
    console.log("response create supply chain:", data.data);
    return data.data;
  }
);
export const updateSupplyChain = createAsyncThunk(
  "update supply chain",
  async (body) => {
    // const dispatch = useDispatch();
    console.log("Body: supply chain", body);
    const data = await axios.put(
      "http://localhost:8080/api/v1/supply-chain",
      body
    );
    console.log("response create supply chain:", data.data);
    return data.data;
  }
);
export const deleteSupplyChainMapping = createAsyncThunk(
  "delete supply chain mapping",
  async (deleteObj) => {
    console.log(
      "id: inside delete process",
      deleteObj.supply_chain_id,
      deleteObj.supply_chain_mapping
    );
    const data = await axios.delete(
      `http://localhost:8080/api/v1/supply-chain/${deleteObj.supply_chain_id}/supply-chain-mapping/${deleteObj.supply_chain_mapping}`
    );
    console.log("response delete process:", data.data);
    return data.data;
  }
);
const supplyChainSlice = createSlice({
  name: "supply-chain",
  initialState: {
    isloading: true,
    isError: false,
    supplyChains: [],
    supplyChainCreationResponse: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupplyChains.fulfilled, (state, action) => {
      state.supplyChains = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(fetchSupplyChains.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.supplyChains = [];
    });
    builder.addCase(fetchSupplyChains.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.supplyChains = [];
    });
    builder.addCase(addSupplyChain.fulfilled, (state, action) => {
      console.log("action addSupplyChain", action);
      state.supplyChainCreationResponse = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(addSupplyChain.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.supplyChainCreationResponse = {};
    });
    builder.addCase(addSupplyChain.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.supplyChainCreationResponse = {};
    });
    builder.addCase(updateSupplyChain.fulfilled, (state, action) => {
      console.log("action updateSupplyChain", action);
      state.supplyChainCreationResponse = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(updateSupplyChain.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.supplyChainCreationResponse = {};
    });
    builder.addCase(updateSupplyChain.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.supplyChainCreationResponse = {};
    });
  },
});
export default supplyChainSlice.reducer;
