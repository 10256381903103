import React from "react";
import { routes } from "./util/constants";
import { Route, Routes, useLocation } from "react-router-dom";
import AdminDashboard from "./ui-components/dashboard/AdminDashboard";
import Home from "./ui-components/Home";
import Process from "./ui-components/Process";
import PageNotFound from "./ui-components/PageNotFound";
import UserDashboard from "./ui-components/dashboard/UserDashboard";
import Login from "./Auth/Login";
import Register from "./Auth/Register";

export default function NavRoutes() {
  let location = useLocation();
  console.log("location", location);
  let role = location?.state?.role;
  const loggedIn = localStorage.getItem("isLoggedIn");
  return (
    <Routes location={location}>
      <Route exact path={routes.HOME} element={<Home />} />
      <Route
        exact
        path={
          !loggedIn
            ? routes.REGISTER
            : role === "Admin"
            ? routes.ADMIN_DASHBOARD
            : routes.USER_DASHBOARD
        }
        element={
          !loggedIn ? (
            <Register />
          ) : role === "Admin" ? (
            <AdminDashboard location={location} />
          ) : (
            <UserDashboard location={location} />
          )
        }
      />
      <Route
        exact
        path={routes.PROCESS}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.LOGIN}
        element={<Login location={location} />}
      />
      <Route
        exact
        path={routes.REGISTER}
        element={<Register location={location} />}
      />
      <Route
        exact
        path={routes.SUBPROCESS}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.ADDSUBPROCESS}
        element={<AdminDashboard location={location} />}
      />

      <Route
        exact
        path={routes.SUPPLYCHAIN}
        element={<UserDashboard location={location} />}
      />
      <Route
        exact
        path={routes.ADDSUPPLYCHAIN}
        element={<UserDashboard location={location} />}
      />
      <Route
        exact
        path={routes.UPDATESUPPLYCHAIN}
        element={<UserDashboard location={location} />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
