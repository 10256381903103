import React, { useState } from "react";
import { Layout } from "antd";
import HeaderAdmin from "../layouts/HeaderAdmin";
import SiderMenu from "./SiderMenu";

import moment from "moment";
import { routes } from "../../util/constants";
import Dashboard from "../Dashboard";

import { useLocation } from "react-router-dom";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { msalConfig } from "../../authConfig";
import { InteractionType } from "@azure/msal-browser";
// import Process from "../Process";
import Process from "./../Process";
import SubProcess from "../SubProcess";
import AddProcess from "../AddProcess";
import SupplyChain from "../SupplyChain";
import AddSupplyChain from "../AddSupplyChain";
import UserSiderMenu from "./UserSiderMenu";
import UpdateSupplyChain from "../UpdateSupplyChain";

const { Sider, Content, Footer } = Layout;

export default function UserDashboard(props) {
  const { instance } = useMsal();
  const [collapsed, setCollapsed] = useState(false);
  const [sideCollapsed, setSideCollapsed] = useState(false);
  const [viewToggleBtn, setViewToggleBtn] = useState(true);
  const location = useLocation();

  const toggle = () => {
    setCollapsed(!collapsed);

    setSideCollapsed(false);
  };
  // const onSwitchChange = (checked) => {
  //   setSideCollapsed(checked);
  //   if (!sideCollapsed) {
  //     setViewToggleBtn(false);
  //   }
  // };
  const handleMouseEnter = () => {
    setViewToggleBtn(true);
  };
  const handleMouseLeave = () => {
    setViewToggleBtn(false);
  };
  const { pathname } = props.location;
  function renderPathName(pathname) {
    switch (pathname) {
      //   case routes.PROCESS:
      //     return <Process location={location} />;
      //   case routes.SUBPROCESS:
      //     return <SubProcess location={location} />;
      //   case routes.ADDPROCESS:
      //     return <AddProcess location={location} />;
      case routes.SUPPLYCHAIN:
        return <SupplyChain location={location} />;
      case routes.ADDSUPPLYCHAIN:
        return <AddSupplyChain location={location} />;
      case routes.UPDATESUPPLYCHAIN:
        return <UpdateSupplyChain location={location} />;
      default:
        return <Dashboard location={location} />;
    }
  }
  const authRequest = {
    ...msalConfig.auth.scopes,
  };
  return (
    <>
      {/* <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      > */}
      <Layout className="admin-dash ">
        <Sider
          width={260}
          breakpoint="sm"
          collapsedWidth={sideCollapsed ? "80" : "0"}
          theme="light"
          trigger={null}
          collapsible
          collapsed={sideCollapsed ? sideCollapsed : collapsed}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={collapsed ? "display-switch" : ""}>
            <div className={!sideCollapsed ? "toogle-off" : "toogle-on"}>
              <span
                className={
                  sideCollapsed && !viewToggleBtn
                    ? "collapsed-switch-visible"
                    : ""
                }
              ></span>
            </div>
          </div>
          <UserSiderMenu
            history={props.history}
            user={props.user}
            collapsed={collapsed}
          ></UserSiderMenu>
        </Sider>
        <Layout className="site-layout">
          <HeaderAdmin
            toggle={toggle}
            collapsed={collapsed}
            user={instance.getActiveAccount()}
            signOut={props.signOut}
          />

          <Content
            style={{
              margin: "30px 10px",

              minHeight: "100vh",
            }}
          >
            {renderPathName(pathname)}
          </Content>
          <Footer
            style={{
              textAlign: "center",
            }}
          >
            ©{moment().year()} Created by EAST-Team
          </Footer>
        </Layout>
      </Layout>
      {/* </MsalAuthenticationTemplate> */}
    </>
  );
}
