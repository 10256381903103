import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

export const login = createAsyncThunk("login user", async (body) => {
  console.log("login user  called ", body);
  try {
    const data = await axios.post("http://localhost:8080/api/v1/login", body);
    console.log("logged in user ", data);
    return data.data;
  } catch (error) {
    console.log(error);
  }
});
export const register = createAsyncThunk("register user", async (body) => {
  console.log("register user  called ", body);
  try {
    const data = await axios.post("http://localhost:8080/api/v1/user", body);
    console.log("register user ", data);
    return data.data;
  } catch (error) {
    console.log(error);
  }
});
const userSlice = createSlice({
  name: "user",
  initialState: {
    isloading: true,
    isError: false,
    loggedInUser: {},
    isLoggedIn: false,
    registeredUser: {},
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.loggedInUser = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(login.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.processes = [];
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.processes = [];
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.registeredUser = action.payload;
      state.isloading = false;
      state.isError = false;
    });
    builder.addCase(register.pending, (state, action) => {
      state.isloading = true;
      state.isError = false;
      state.processes = [];
    });
    builder.addCase(register.rejected, (state, action) => {
      state.isloading = false;
      state.isError = true;
      state.processes = [];
    });
  },
});
export default userSlice.reducer;
