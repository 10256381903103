import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { msalConfig } from "../authConfig";
import { Navigate } from "react-router-dom";
import { routes } from "../util/constants";
import Login from "../Auth/Login";
import Register from "../Auth/Register";

export default function Home() {
  console.log("--------------home-------------");
  // const { instance } = useMsal();
  // const handleLoginRedirect = () => {
  //   console.log("handle login redirect");
  //   instance
  //     .loginRedirect({ scopes: [...msalConfig.auth.scopes] })
  //     .catch((error) => console.log(error));
  // };
  // const RedirectToLogin = () => <>{handleLoginRedirect()}</>;
  const loggedIn = localStorage.getItem("isLoggedIn");
  console.log("loggedIn", loggedIn);
  return (
    <>
      {/* <UnauthenticatedTemplate>
        <RedirectToLogin />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Navigate to={routes.ADMIN_DASHBOARD} />
      </AuthenticatedTemplate> */}
      {/* <Login /> */}
      {loggedIn ? <Navigate to={routes.ADMIN_DASHBOARD} /> : <Register />}
    </>
  );
}
