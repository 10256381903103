import React from "react";
import Breadcrumb from "./BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Col, Form, Modal, Popconfirm, Row, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  // EyeOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table, message } from "antd";
import Highlighter from "react-highlight-words";
import {
  fetchProcess,
  addProcess,
  updateProcess,
  deleteProcess,
} from "../redux-slice/processSlice";

export default function Process(props) {
  const dispatch = useDispatch();
  const [isEditProcess, setIsEditProcess] = useState(false);
  const [selectEditProcess, setSelectEditProcess] = useState({});
  const response = useSelector((store) => store);
  const naviagte = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [process, setProcess] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const showModal = () => {
    // setIsEditSubProcess(false);
    setIsModalOpen(true);
  };
  const deleteTheProcess = async (subProcess) => {
    // console.log("subProcess", subProcess);
    let responseFromApi = await dispatch(deleteProcess(subProcess.id));
    console.log("responseFromApi from delete", responseFromApi.payload);
    if (responseFromApi.payload.status === 204) {
      message.success("Process deleted successfully");
      dispatch(fetchProcess());
    } else {
      message.error(responseFromApi.payload.message);
    }
  };
  const editSubProcess = (record) => {
    console.log("record:", record);
    setIsEditProcess(true);
    setSelectEditProcess(record);
    setIsModalOpen(true);
  };
  const onSubmit = async (process) => {
    let processObj, responseFromApi;
    if (isEditProcess) {
      processObj = {
        id: selectEditProcess.id,
        name: process.name,
        created_by: "Gurvinder Singh",
        updated_by: "Gurvinder Singh",
      };
      responseFromApi = await dispatch(updateProcess(processObj));
    } else {
      processObj = {
        name: process.name,
        created_by: "Gurvinder Singh",
        updated_by: "Gurvinder Singh",
      };
      responseFromApi = await dispatch(addProcess(processObj));
    }
    setIsModalOpen(false);
    setIsEditProcess(false);
    dispatch(fetchProcess());
    console.log("responseFromApi.payload:", responseFromApi.payload);
    if (responseFromApi.payload.status === 200) {
      message.success(responseFromApi.payload.message);
      dispatch(fetchProcess());
      console.log("processes after submit", responseFromApi.payload);
    } else {
      message.error(responseFromApi.payload.message);
    }
  };
  const handleNavigate = (row) => {
    console.log("Data:", row);
    naviagte(`/processes/subprocess`, { state: row });
  };

  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    confirm({
      closeDropdown: false,
    });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);

    // setSearchText("");
  };
  const handleCancel = () => {
    setIsEditProcess(false);
    setIsModalOpen(false);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, [], confirm, dataIndex);
              // handleReset([], confirm, dataIndex);
              // confirm({
              //   closeDropdown: false,
              // });
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // const [process, setProcess] = useState([]);

  useEffect(() => {
    dispatch(fetchProcess());
  }, [dispatch]);
  useEffect(() => {
    console.log("Process: of useeffect", response.process.processes);
    setProcess(response.process.processes);
  }, [response]);

  const columns = [
    // {
    //   title: "Process Id",
    //   dataIndex: "id",
    //   key: "processId",
    //   width: "30%",
    //   ...getColumnSearchProps("id"),
    // },
    {
      title: "Process Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      // align: "center",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Sub-Process",
      dataIndex: "subProcesses",
      key: "subProcesses",
      width: "30%",
      align: "center",

      render: (data, row) => (
        // <Tooltip title="View Process">
        <Button type="link" onClick={() => handleNavigate(row)}>
          View Sub Process
        </Button>
        // </Tooltip>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "center",

      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit Process">
            <Button type="link" onClick={() => editSubProcess(record)}>
              <EditOutlined style={{ fontSize: "20px", color: "Blue" }} />
            </Button>
          </Tooltip>
          <Tooltip title="Delete Process">
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => deleteTheProcess(record)}
            >
              <DeleteOutlined style={{ fontSize: "20px", color: "red" }} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];
  console.log("response", response);
  return (
    <div className="container">
      <Row className="title-row">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Breadcrumb location={props.location} />
        </Col>
      </Row>
      <Row className="statistics-card">
        <Col span={24}>
          <div className="card">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <div>Process</div>
              <div style={{ flex: "0 0 " }}>
                <Button icon={<PlusOutlined />} onClick={() => showModal()}>
                  Add Process
                </Button>
              </div>
            </div>
            <Table
              loading={response.process.processes?.length ? false : true}
              columns={columns}
              dataSource={process}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title="Add Process"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
        // style={{ top: 20 }}
        styles={{ body: { height: "70px", top: 20 } }}
      >
        <Form
          name="add-Group"
          className="add-Group"
          onFinish={onSubmit}
          initialValues={
            isEditProcess
              ? {
                  name: selectEditProcess.name,
                }
              : ""
          }
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please Enter Process Name!",
              },
            ]}
          >
            <Input
              autoFocus
              placeholder="Process Name"
              name="name"
              //   value={selectedSubProcessName}
              // onChange={this.onChange}
            />
          </Form.Item>
          <Form.Item className="float-end">
            <Button className="close-modal me-3" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="ok-modal">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
