import React from "react";
import "./App.css";
import "antd/dist/reset.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";
import NavRoutes from "./NavRoutes";
import { Provider } from "react-redux";
import store from "./redux-slice/store";

function App({ instance }) {
  return (
    <Provider store={store}>
      <MsalProvider instance={instance}>
        <BrowserRouter>
          <NavRoutes />
        </BrowserRouter>
      </MsalProvider>
    </Provider>
  );
}

export default App;
