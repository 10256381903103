import React from "react";
import Breadcrumb from "./BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import {
  Col,
  Divider,
  Form,
  List,
  Modal,
  Popconfirm,
  Row,
  Typography,
  message,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import { fetchProcess, addProcess } from "../redux-slice/processSlice";

export default function AddProcess(props) {
  const location = useLocation();
  const dispatch = useDispatch();

  const naviagte = useNavigate();
  const [data, setData] = useState([]);
  const [processName, setProcessName] = useState("");
  const [count, setCount] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditSubProcess, setIsEditSubProcess] = useState(false);
  const [selectedSubProcessName, setSelectedSubProcessName] = useState("");
  const [selectedSubProcessKey, setSelectedSubProcessKey] = useState("");
  const [
    selectedSubProcessEmissionFactor,
    setSelectedSubProcessEmissionFactor,
  ] = useState("");
  const [selectedSubProcessUnit, setSelectedSubProcessUnit] = useState("");

  const showModal = () => {
    setIsEditSubProcess(false);
    setIsModalOpen(true);
  };

  const submitAddProcess = () => {
    let addProcessObj = {
      name: processName[0],
      created_by: "Gurvinder Singh",
      updated_by: "Gurvinder Singh",
    };
    const addProcessData = dispatch(addProcess(addProcessObj));
    console.log("addProcessData:", addProcessData);
    console.log("Process Name:", processName[0]);
    console.log("subProcesses:", data);
    message.success("supply chain created successfully");
  };
  useEffect(() => {
    console.log("Add Process Subprocess:", location.state.name);
  }, []);
  const editSubProcess = (record) => {
    console.log("record:", record);
    setIsEditSubProcess(true);
    setSelectedSubProcessKey(record.key);
    setSelectedSubProcessName(record.name);
    setSelectedSubProcessEmissionFactor(record.emission_factor);
    setSelectedSubProcessUnit(record.unit);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const onSubmit = (subProcess) => {
    if (isEditSubProcess) {
      subProcess.emission_factor = Number(subProcess.emission_factor);
      let indexToEdit = data.findIndex((item) => item.key === subProcess.key);
      data[indexToEdit] = subProcess;
      setData(Object.assign([], data));
      setIsModalOpen(false);
    } else {
      subProcess.key = count;
      subProcess.emission_factor = Number(subProcess.emission_factor);
      setData([subProcess].concat(data));
      console.log(data);
      setCount(count + 1);
      setIsModalOpen(false);
    }
  };
  const deleteSubProcess = (subProcess) => {
    let newData = data.filter((data) => data.key !== subProcess.key);
    setData(newData);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "SubProcess Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Emission Factor",
      dataIndex: "emission_factor",
      key: "emission_factor",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => editSubProcess(record)}>Edit</Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteSubProcess(record)}
          >
            <DeleteOutlined style={{ fontSize: "20px", color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="container">
      <Row className="title-row">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Breadcrumb location={props.location} />
        </Col>
      </Row>
      <Row className="statistics-card">
        <Col span={24}>
          <div className="card">
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <div>Add Sub-Process</div>
              <div style={{ flex: "0 0 " }}></div>
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
              }}
            >
              <div>{location.state.name}</div>
              {/* <Input
                onChange={(e) =>
                  setProcessName(e.target.value ? [e.target.value] : [])
                }
                placeholder="Process Name"
                style={{
                  width: "35%",
                }}
              /> */}
              <Button icon={<PlusOutlined />} onClick={showModal}>
                Add Sub-Process
              </Button>
            </div>
            {data.length > 0 ? (
              <Table columns={columns} dataSource={data} />
            ) : (
              ""
            )}
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                type="primary"
                className="ok-modal"
                onClick={submitAddProcess}
              >
                Add Process
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        title="Sub Process"
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
        // style={{ top: 20 }}
        styles={{ body: { height: "180px", top: 20 } }}
      >
        <Form
          name="add-Group"
          className="add-Group"
          onFinish={onSubmit}
          initialValues={
            isEditSubProcess
              ? {
                  name: selectedSubProcessName,
                  emission_factor: selectedSubProcessEmissionFactor,
                  unit: selectedSubProcessUnit,
                  key: selectedSubProcessKey,
                }
              : ""
          }
        >
          <Form.Item name="key" style={{ display: "none" }}></Form.Item>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please Enter Sub Process Name!",
              },
            ]}
          >
            <Input
              autoFocus
              placeholder="Sub Process Name"
              name="name"
              //   value={selectedSubProcessName}
              //   onChange={this.onChange}
            />
          </Form.Item>
          <Form.Item
            name="emission_factor"
            rules={[
              {
                required: true,
                message: "Please Enter Emission Factor!",
              },
            ]}
          >
            <Input
              autoFocus
              placeholder="Emission Factor"
              name="emission_factor"
              //   value={selectedSubProcessEmissionFactor}
              //   onChange={this.onChange}
            />
          </Form.Item>
          <Form.Item
            name="unit"
            rules={[
              {
                required: true,
                message: "Please Enter Unit!",
              },
            ]}
          >
            <Input
              autoFocus
              placeholder="Unit"
              name="unit"
              value={selectedSubProcessUnit}
              //   onChange={this.onChange}
            />
          </Form.Item>
          <Form.Item className="float-end">
            <Button className="close-modal me-3" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="ok-modal"
              //   loading={loading}
            >
              OK
            </Button>
          </Form.Item>
        </Form>
        {/* <div className="p-2">
          <Input placeholder="Sub Process Name" />
        </div>
        <div className="p-2">
          <Input placeholder="Carbon Emission" />
        </div>
        <div className="p-2">
          <Input placeholder="Unit" />
        </div>
        <div className="p-2 mx-auto" style={{ width: "200px" }}>
          <Button onClick={handleOk}>Add Sub-Process</Button>
        </div> */}
      </Modal>
    </div>
  );
}
