export const routes = {
  HOME: "/",
  USER_DASHBOARD: "/dashboard",
  ADMIN_DASHBOARD: "/dashboard",
  PROCESS: "/process",
  SUBPROCESS: "/processes/subprocess",
  ADDPROCESS: "/processes/addprocess",
  SUPPLYCHAIN: "/supply-chain",
  ADDSUPPLYCHAIN: "/supply-chain/add-supply-chain",
  ADDSUBPROCESS: "/processes/subprocess/addsubprocess",
  UPDATESUPPLYCHAIN: "/supply-chain/update-supply-chain",
  LOGIN: "/login",
  REGISTER: "/register",
};

export const roles = ["Admin", "User"];
