import { configureStore } from "@reduxjs/toolkit";
import ProcessSlice from "./processSlice";
import supplyChainSlice from "./supplyChainSlice";
import supplyChainCategorySlice from "./supplyChainCategorySlice";
import userSlice from "./userSlice";

const store = configureStore({
  reducer: {
    process: ProcessSlice,
    supplyChain: supplyChainSlice,
    supplyChainCategory: supplyChainCategorySlice,
    user: userSlice,
  },
});
export default store;
