import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button, Form, Input, message } from "antd";
import { login } from "../redux-slice/userSlice";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./Login.css";
import loginsideimg from "../../src/assests/img/Loginimage.jpg";
import axios from "axios";

export default function Login() {
  const responseFromStore = useSelector((store) => store);
  const navigate = useNavigate();
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    // if (localStorage.getItem("token")) {
    //   if (
    //     localStorage.getItem("access_level") === "ADMIN" ||
    //     localStorage.getItem("access_level") === "ORGANIZATION"
    //   ) {
    //     navigate("/organization");
    //   } else {
    //     navigate("/projects");
    //   }
    // }
  }, []);

  const onChangeData = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSubmit = async () => {
    const data = { email: userData.email, password: userData.password };
    setLoading(true);
    console.log("data", data);
    let responseFromApi = await dispatch(login(data));
    console.log("responseFromApi", responseFromApi);
    console.log("responseFromStore", responseFromStore);
    if (responseFromApi.payload) {
      localStorage.setItem("user", JSON.stringify(responseFromApi?.payload));
      console.log(
        "User LocalStorage",
        JSON.parse(localStorage.getItem("user"))
      );
      localStorage.setItem("role", responseFromApi.payload.role);
      // localStorage.setItem("token", responseFromApi.payload?.token);
      localStorage.setItem("isLoggedIn", true);
      navigate("/dashboard", { state: responseFromApi.payload });
    }
  };
  return (
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper">
          <img src={loginsideimg} alt="Login" style={{ height: "450px" }} />
        </div>

        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinishFailed={onFinishFailed}
        >
          <p className="form-title">Welcome back</p>
          <p>Login to the Dashboard</p>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input
              placeholder="Email/UserName"
              name="email"
              prefix={<UserOutlined />}
              onChange={onChangeData}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              name="password"
              prefix={<LockOutlined />}
              type="password"
              onChange={onChangeData}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loading}
              onClick={handleSubmit}
            >
              LOGIN
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
