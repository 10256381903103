import React, { useState, useEffect, Component } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Select } from "antd";
import { routes, roles } from "../../src/util/constants";
import { useNavigate } from "react-router-dom";
import loginsideimg from "../../src/assests/img/Loginimage.jpg";
import { register } from "../redux-slice/userSlice";
const { Option } = Select;

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    phoneNumber: "",
    created: false,
  });
  const onFinish = async (values) => {
    console.log("Success:", values);
    let responseFromApi = await dispatch(register(values));
    if (responseFromApi.payload) {
      navigate("/login", { state: responseFromApi.payload });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChange = (e) => {
    console.log(e.target.name);
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  return (
    <div className="login-page">
      <div className="login-box">
        <div className="illustration-wrapper">
          <img style={{ height: "450px" }} src={loginsideimg} alt="register" />
        </div>
        <Form
          name="login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="name"
            // tooltip="What do you want others to call you?"
            rules={[
              {
                required: true,
                message: "Please input your Name!",
                whitespace: true,
              },
            ]}
          >
            <Input
              name="name"
              style={{ height: "45px", padding: "0 10px 0 12px" }}
              rules={[
                {
                  required: true,
                  message: "Please input your Name!",
                  whitespace: true,
                },
              ]}
              placeholder="Name"
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item
            name="email"
            // tooltip="What do you want others to call you?"
            rules={[
              {
                required: true,
                message: "Please input your email!",
                whitespace: true,
              },
            ]}
          >
            <Input
              name="email"
              style={{ height: "45px", padding: "0 10px 0 12px" }}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
              placeholder=" Email"
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item
            name="password"
            placeholder="Enter Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
                whitespace: true,
              },
            ]}
            hasFeedback
            value={user.password}
            onChange={onChange}
          >
            <Input.Password
              placeholder="Password"
              style={{ height: "45px", padding: "0 10px 0 12px" }}
              name="password"
              type="password"
              onChange={onChange}
            />
          </Form.Item>
          <Form.Item
            // {...restField}
            name="role"
            rules={[
              {
                required: true,
                message: "Missing role",
              },
            ]}
          >
            <Select
              style={{ height: "45px" }}
              showSearch
              allowClear={true}
              placeholder="Select a role"
              optionFilterProp="children"
              // onChange={onChange}
              name="role"
              // filterOption={filterOptionProcess}
              // onSearch={onSearchProcess}
            >
              {roles?.map((role) => (
                <Option value={role}>{role}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            // tooltip="What do you want others to call you?"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
                whitespace: true,
              },
            ]}
          >
            <Input
              style={{ height: "45px", padding: "0 10px 0 12px" }}
              name="phoneNumber"
              rules={[
                {
                  type: "number",
                  message: "The input is not valid phone number!",
                },
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
              placeholder=" Phone Number"
              onChange={onChange}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Register
            </Button>
          </Form.Item>
          <a>
            Already have an account? &nbsp;
            <NavLink to={routes.LOGIN}>Log in</NavLink>
          </a>
        </Form>
      </div>
    </div>
  );
}
