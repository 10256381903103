import React from "react";
import Breadcrumb from "./BreadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import {
  Col,
  Divider,
  Form,
  List,
  Modal,
  Popconfirm,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowDownOutlined,
  DeleteOutlined,
  EyeOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Table } from "antd";
import Highlighter from "react-highlight-words";
import {
  fetchSupplyChains,
  updateSupplyChain,
  deleteSupplyChainMapping,
} from "./../redux-slice/supplyChainSlice";
import { fetchProcess, addProcess } from "../redux-slice/processSlice";

export default function UpdateSupplyChain(props) {
  const [form] = Form.useForm();
  const location = useLocation();
  const { Option } = Select;
  const dispatch = useDispatch();
  const response = useSelector((store) => store);

  const naviagte = useNavigate();
  const [process, setProcess] = useState([]);
  const [supplyChainProcess, setSupplyChainProcess] = useState([]);

  const [supplyChain, setSupplyChain] = useState({});
  const [deletedSupplyChainProcesses, setDeletedSupplyChainProcesses] =
    useState([]);

  const [searchText, setSearchText] = useState("");
  const [subProcess, setSubProcess] = useState([]);
  //   const [process, setProcess] = useState({});
  const [searchedColumn, setSearchedColumn] = useState("");
  //   const [subProcess, setSubProcess] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditSubProcess, setIsEditSubProcess] = useState(false);
  const [isDeleteSubProcess, setIsDeleteSubProcess] = useState(false);

  const [selectedSubProcessName, setSelectedSubProcessName] = useState("");
  const [selectedSubProcessKey, setSelectedSubProcessKey] = useState("");
  const [
    selectedSubProcessEmissionFactor,
    setSelectedSubProcessEmissionFactor,
  ] = useState("");

  const [selectedSubProcessUnit, setSelectedSubProcessUnit] = useState("");
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsEditSubProcess(false);
    setIsModalOpen(true);
  };

  const deleteSupplyChainProcess = (deletedSupplyChainProcess) => {
    // setSupplyChainProcess();
    setIsDeleteSubProcess(true);

    setDeletedSupplyChainProcesses((deletedSupplyChainProcesses) => [
      ...deletedSupplyChainProcesses,
      deletedSupplyChainProcess.id,
    ]);
    // let newData = data.filter((data) => data.key !== subProcess.key);
    // setData(newData);
    let tempSupplyChain = supplyChainProcess;
    const filteredSupplyChain = tempSupplyChain.filter(
      (item) => item.id !== deletedSupplyChainProcess.id
    );
    setSupplyChainProcess(filteredSupplyChain);
  };
  useEffect(() => {
    dispatch(fetchSupplyChains());
  }, [dispatch]);

  useEffect(() => {
    // let filtered = [
    //   ...response.supplyChain.supplyChains?.filter((supplyChain) => {
    //     return supplyChain.id === location.state.id;
    //   })[0]?.supplyChainMappings,
    // ];
    // console.log("filtered:", filtered);

    let fileredArray = [];
    // let sortedArray = filtered?.sort((a, b) => {
    //   console.log("111");
    //   // Handle undefined or missing sequence values gracefully
    //   let sequenceA = a.sequence ?? Number.MAX_VALUE;
    //   console.log("114");

    //   // Assign a large placeholder
    //   let sequenceB = b.sequence ?? Number.MAX_VALUE;
    //   console.log("118");
    //   console.log(sequenceA - sequenceB);
    //   return sequenceA - sequenceB;
    // });
    // let sortedArray = filtered?.sort((a, b) => a.sequence - b.sequence);
    // console.log("sortedArray:", sortedArray);
    setSupplyChainProcess(
      [
        ...(response.supplyChain.supplyChains?.filter((supplyChain) => {
          return supplyChain.id === location.state.id;
        })?.[0]?.supplyChainMappings ?? []), // Use ?? to provide a default empty array
      ].sort((a, b) => a.sequence - b.sequence)
    );
    // setSupplyChainProcess(
    //   response.supplyChain.supplyChains?.filter((supplyChain) => {
    //     return supplyChain.id === location.state.id;
    //   })[0]?.supplyChainMappings
    // );
    setSupplyChain(
      response.supplyChain.supplyChains.filter((supplyChain) => {
        return supplyChain.id === location.state.id;
      })[0]
    );
  }, [response]);
  const searchInput = useRef(null);

  const deleteAndUpdateSupplyChain = async () => {
    // console.log("deletedSupplyChainProcesses:", deletedSupplyChainProcesses);
    for (let index = 0; index < deletedSupplyChainProcesses.length; index++) {
      let deleteObj = {
        supply_chain_id: supplyChain.id,
        supply_chain_mapping: deletedSupplyChainProcesses[index],
      };
      let responseFromApi = await dispatch(deleteSupplyChainMapping(deleteObj));
    }

    let supplyChainMappings = supplyChainProcess;
    let updateValue = [];
    for (let index = 0; index < supplyChainMappings.length; index++) {
      let mapping = Object.assign({}, supplyChainMappings[index]);
      mapping.process_id = mapping.process.id;
      mapping.sub_process_id = mapping.subProcess.id;

      updateValue.push(mapping);
    }
    let updateSupplyChainObj = {
      id: supplyChain.id,
      user_id: supplyChain.userId,
      category_id: supplyChain.supplyChainCategoryMasterData.id,
      name: supplyChain.name,
      supply_chain_mapping: updateValue,
    };
    let result = await dispatch(updateSupplyChain(updateSupplyChainObj));

    if (result.payload.status === 200) {
      form.setFieldsValue({ supply_chain: [] });
      message.success("supply chain updated successfully");
      dispatch(fetchSupplyChains());
      setIsDeleteSubProcess(false);
    }
  };
  const onFinish = async (values) => {
    let supplyChainMappings = supplyChain.supplyChainMappings;
    let updateValue = [];
    for (let index = 0; index < supplyChainMappings.length; index++) {
      let mapping = Object.assign({}, supplyChainMappings[index]);
      mapping.process_id = mapping.process.id;
      mapping.sub_process_id = mapping.subProcess.id;

      updateValue.push(mapping);
    }
    let finalMapping = [...updateValue, ...values.supply_chain];
    let updateSupplyChainObj = {
      id: supplyChain.id,
      user_id: supplyChain.userId,
      category_id: supplyChain.supplyChainCategoryMasterData.id,
      name: supplyChain.name,
      supply_chain_mapping: finalMapping,
    };
    let result = await dispatch(updateSupplyChain(updateSupplyChainObj));
    if (result.payload.status === 200) {
      form.setFieldsValue({ supply_chain: [] });
      message.success("supply chain updated successfully");
      dispatch(fetchSupplyChains());
    }
  };
  const onChangeProcess = (value) => {
    setSubProcess(
      process.filter((process) => {
        return process.id === value;
      })[0]?.subProcesses
    );
  };
  const filterOptionProcess = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onSearchProcess = (value) => {
    console.log("search:", value);
  };
  const onChangeSubProcess = (value) => {
    // console.log(
    //   `selected ${process.filter((process) => process.id === value)}`
    // );
  };
  const filterOptionSubProcess = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onSearchSubProcess = (value) => {
    console.log("search:", value);
  };

  useEffect(() => {
    dispatch(fetchProcess());
  }, [dispatch]);
  useEffect(() => {
    // setSupplyChain(location.state);

    setProcess(response.process.processes);
  }, [response]);
  const columns = [
    {
      title: "Process Name",
      dataIndex: "name",
      key: "name",
      // width: "20%",
      render: (_, data) => <>{`${data.process.name}`}</>,
    },
    {
      title: "Sub Process Name",
      dataIndex: "subProcessName",
      key: "subProcessName  ",
      // width: "20%",
      render: (_, data) => <>{`${data.subProcess.name} `}</>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (_, data) => (
        <>{`${data.quantity} ${data.subProcess.unit.split("/")[1]}`}</>
      ),
      // width: "20%",
    },
    {
      title: "Emission per Unit",
      dataIndex: "",
      key: "total_carbon_emission",
      render: (_, data) => (
        <>{`${data.subProcess.emission_factor} ${data.subProcess.unit}`}</>
      ),
    },
    {
      title: "Total Process Emission",
      dataIndex: "",
      key: "total_carbon_emission",
      render: (_, data) => (
        <>{`${data.processFootprint?.toFixed(3)} ${data.subProcess.unit}`}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <Button onClick={() => editSubProcess(record)}>Edit</Button> */}
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteSupplyChainProcess(record)}
          >
            <DeleteOutlined style={{ fontSize: "20px", color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div className="container">
      <Row className="title-row">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Breadcrumb location={props.location} />
        </Col>
      </Row>
      <Row className="statistics-card">
        <Col span={24}>
          <div className="card">
            <Form
              form={form}
              name="dynamic_form_nest_item"
              onFinish={onFinish}
              autoComplete="off"
              //   initialValues={{
              //     supply_chain: [{}],
              //   }}
            >
              <Form.List name="supply_chain">
                {(fields, { add, remove, move }) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 10,
                      }}
                    >
                      <div>{supplyChain?.name}</div>
                      <Button
                        icon={<PlusOutlined />}
                        onClick={() => {
                          add();
                          move(fields.length, 0);
                        }}
                      >
                        Add More Process
                      </Button>
                    </div>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          // marginBottom: 8,
                          // display: "flex",
                          // justifyContent: "space-between",
                          padding: "4px 0 0 9px",
                        }}
                        align="baseline"
                      >
                        <Form.Item>Select Process : </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "process_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Process",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "200px" }}
                            showSearch
                            allowClear={true}
                            placeholder="Select a process"
                            optionFilterProp="children"
                            onChange={onChangeProcess}
                            filterOption={filterOptionProcess}
                            onSearch={onSearchProcess}
                          >
                            {process?.map((group) => (
                              <Option value={group.id}>{group.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "sub_process_id"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Sub Process",
                            },
                          ]}
                        >
                          <Select
                            style={{ width: "200px" }}
                            showSearch
                            allowClear={true}
                            placeholder="Select a Sub Process"
                            optionFilterProp="children"
                            onChange={onChangeSubProcess}
                            filterOption={filterOptionSubProcess}
                            onSearch={onSearchSubProcess}
                          >
                            {subProcess?.map((group) => (
                              <Option value={group.id}>{group.name}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "quantity"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Quantity",
                            },
                          ]}
                        >
                          <Input placeholder="Quantity" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "sequence"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Sequence",
                            },
                          ]}
                        >
                          <Input placeholder="Sequence" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <div style={{ textAlign: "center" }}>
                      {fields.length ? (
                        <Button
                          type="primary"
                          className="ok-modal"
                          htmlType="submit"
                        >
                          Update Supply Chain
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </Form.List>
            </Form>
            <Table
              columns={columns}
              dataSource={supplyChainProcess}
              pagination={false}
              loading={response.supplyChain.isloading}
            />
            <div
              className="box-area"
              style={{
                margin: "20px 0 0 0",
                textAlign: "center",
                alignSelf: "center",
                width: "max-content",
              }}
            >
              {supplyChainProcess?.map((item, index) => (
                <div className="box" key={index} style={{ margin: "15px" }}>
                  <div
                    style={{
                      padding: "10px",
                      border: "1px solid",
                      boxShadow: "4px 4px 10px ",
                    }}
                  >
                    <div>
                      <b>Process:</b> {item.subProcess.name}
                    </div>
                    <div>
                      <b>Total Emission:</b>
                      {`${item.processFootprint?.toFixed(3)} ${
                        item.subProcess.unit
                      }`}
                    </div>
                  </div>
                  <br />
                  {index !== supplyChainProcess.length - 1 && (
                    <div>
                      <ArrowDownOutlined
                        style={{
                          color: "blue",
                          // margin: "20px 0 0 0",
                          fontSize: "x-large",
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div
              style={{
                textAlign: "center",
                padding: "10px 15px 0 0",
                fontSize: "medium",
              }}
            >
              {!isDeleteSubProcess ? (
                !response.supplyChain.isloading ? (
                  <div>
                    <b>Total Carbon Footprint: </b>
                    {supplyChain?.totalCarbonEmission?.toFixed(3) +
                      " " +
                      "Kg CO2/Kg"}
                  </div>
                ) : (
                  ""
                )
              ) : (
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    className="ok-modal"
                    onClick={() => deleteAndUpdateSupplyChain()}
                  >
                    Update Supply Chain
                  </Button>
                </div>
              )}
            </div>
          </div>
          <Modal
            title="Sub Process"
            open={isModalOpen}
            // onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
            // style={{ top: 20 }}
            styles={{ body: { height: "180px", top: 20 } }}
          >
            <Form
              name="add-Group"
              className="add-Group"
              // onFinish={onSubmit}
              //   initialValues={
              //     isEditSubProcess
              //       ? {
              //           name: selectedSubProcessName,
              //           emission_factor: selectedSubProcessEmissionFactor,
              //           unit: selectedSubProcessUnit,
              //           key: selectedSubProcessKey,
              //         }
              //       : ""
              //   }
            >
              <Form.Item name="key" style={{ display: "none" }}></Form.Item>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Sub Process Name!",
                  },
                ]}
              >
                <Input
                  autoFocus
                  placeholder="Sub Process Name"
                  name="name"
                  //   value={selectedSubProcessName}
                  //   onChange={this.onChange}
                />
              </Form.Item>
              <Form.Item
                name="emission_factor"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Emission Factor!",
                  },
                ]}
              >
                <Input
                  autoFocus
                  placeholder="Emission Factor"
                  name="emission_factor"
                  //   value={selectedSubProcessEmissionFactor}
                  //   onChange={this.onChange}
                />
              </Form.Item>
              <Form.Item
                name="unit"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Unit!",
                  },
                ]}
              >
                <Input
                  autoFocus
                  placeholder="Unit"
                  name="unit"
                  value={selectedSubProcessUnit}
                  //   onChange={this.onChange}
                />
              </Form.Item>
              <Form.Item className="float-end">
                <Button className="close-modal me-3" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ok-modal"
                  //   loading={loading}
                >
                  OK
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </Col>
      </Row>
    </div>
  );
}
